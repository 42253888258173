import { Login, RegistrationLayout, StyledBreadcrumbs } from '@/components';
import { ResponsiveContainer } from '@/styles';
import { useLogin } from '@/zustand';
import { Container, Paper } from '@mui/material';
import { NextPage } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import type { ReactElement } from 'react';
import useSetHeaderData from 'src/logic/hooks/use-header';
import { sm } from 'src/site-map';
import { VerifyEmail } from 'src/ui/components/smart/login/verify-email';

type Props = {};

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale)),
    },
  };
}

const Page: NextPage<Props> = ({ }) => {
  const needsVerification = useLogin(state => state.needsVerification);
  const contentComponent = () => {
    return needsVerification ? <VerifyEmail></VerifyEmail> : <Login></Login>
  }
  useSetHeaderData('', null)
  return (
    <ResponsiveContainer sx={{ mb: 8, minHeight: 500 }}>
      <StyledBreadcrumbs alias={sm.login.url} />
      <Container sx={{ mt: 4, p: '0px !important' }} maxWidth='sm'>
        <Paper elevation={4} sx={{ p: [2, 3, 4] }}>
          {contentComponent()}
        </Paper>
      </Container>
    </ResponsiveContainer>
  );
};

Page.getLayout = function getLayout(page: ReactElement) {
  return <RegistrationLayout>{page}</RegistrationLayout>;
};

export default Page;
