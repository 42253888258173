import { useLogin } from '@/zustand';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { VerifyEmailForm } from '..';
import { getLoginTranslationKey } from '@/hooks';

interface VerifyEmailProps {}

export const VerifyEmail: React.FC<VerifyEmailProps> = (props: VerifyEmailProps) => {
  const { t } = useTranslation();
  const getEmail = useLogin(state => state.getEmail)
  return (
    <>
      <Grid item xs={12} sx={{ pb: 2 }}>
        <Typography variant='h1' sx={{ fontWeight: 'bolder' }}>
          {t(getLoginTranslationKey('headers.email_not_verified'))}
        </Typography>
        <Typography>
          {t(getLoginTranslationKey('paragraphs.email_not_verified'))}
        </Typography>
      </Grid>
      <VerifyEmailForm email={getEmail()}/>
    </>
  );
};
